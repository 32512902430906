import { useStaticQuery, graphql } from "gatsby"

const useRecommended = () => {
  const data = useStaticQuery(graphql`
    {
      allStrapiTopJobs(
        sort: { order: DESC, fields: DatePublication }
      ) {
        nodes {
          Content
          DatePublication(formatString: "MMMM D, YYYY")
          Keywords
          MetaDescription
          Slug
          Thumb {
            url
          }
          Title
          Top5Jobs {
            Title
            job {
              Manualid
            }
          }
        }
      }
    }
  `)
  return data.allStrapiTopJobs.nodes.map(jobs => ({
    Content: jobs.Content,
    DatePublication: jobs.DatePublication,
    Keywords: jobs.Keywords,
    MetaDescription: jobs.MetaDescription,
    Slug: jobs.Slug,
    Thumb: jobs.Thumb,
    Title: jobs.Title,
    // best_job: jobs.best_job,
  }))
}

export default useRecommended
